import React from "react";
import { Link } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import "./Priser.css";

const Priser = () => {
  return (
    <div className="pricing">
      <div className="pricing__header">
        <div className="pricing__content">
          <h1 className="pricingHeader__title animate__animated animate__fadeIn ">
            Våra priser
          </h1>
          <p className="pricingHeader__text animate__animated animate__fadeIn">
            Nedan finner du en kort översikt över våra priser. Vid frågor eller
            funderingar är du välkommen att höra av dig till oss.
          </p>
          <div className="home__btn animate__animated animate__zoomIn ">
            <Link to="/kontakta-oss" key={"6"} id={"6"}>
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>
        <div className="image__gradient"></div>
      </div>

      <h2 className="headingTwo">Prislista</h2>
      <p className="pricing__text">
        Vi har listat priser på några av våra mest efterfrågade tjänster. Vi
        strävar efter att erbjuda transparenta och konkurrenskraftiga priser för
        att möta dina behov. För en mer detaljerad prisuppskattning är du varmt
        välkommen att kontakta oss - vi hjälper dig gärna!
      </p>

      <div className="pricing__container">
        <div className="product-list__spc">
          <div className="product__content">
            <div className="card-title__spc">
              <h3>Företagsadministration</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__listTwo">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Fakturering och bokföring = fr. 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Momsredovisning = 499 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Löneadministration = fr. 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Virtuell assistent/kundsupport = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Mötesplanering och reseplanering = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skapa Google profil = 699 kr/st
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Setup bokföringssystem = 399 kr/h
                    </li>
                  </div>
                </div>

                <div className="card-text">
                  <p>
                    Utifrån era behov kan vi erbjuda förmånliga paketpriser i
                    samband med en offert. Genom att kombinera flera av våra
                    tjänster skapar vi en lösning som är både kostnadseffektiv
                    och skräddarsydd för er.
                  </p>
                  <p>
                    Hör av er idag, så tar vi fram ett förslag som passar just
                    er.
                  </p>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="product__list">
          <div className="product__content">
            <div className="card-title">
              <h3>Juridisk konsultation och rådgivning</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__list">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Juridisk rådgivning = 599 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Ansökan om arbetstillstånd (arbetsgivare) = 5 000 kr per
                      anställd
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Ansökan om arbetstillstånd (arbetstagare) = 4 500 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Förlängning av uppehållstillstånd = 4 250 kr
                    </li>
                  </div>
                </div>

                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Ansökan om permanent uppehållstillstånd = 5 500 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Ansökan om att flytta till någon i Sverige = 4 500 kr (med
                      500 kr tillägg per person)
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Rådgivning kring migrationsfrågor = 599 kr/h Vi erbjuder
                      alltid en gratis konsultation.
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="product-list__spc">
          <div className="product__content">
            <div className="card-title__spc">
              <h3>Administration för privatpersoner</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__listTwo">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Hjälp med att betala fakturor = 29 kr/faktura
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Hjälp med att betala fakturor (prenumeration) = fr. 99
                      kr/mån
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Privatadministration = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Kontakt med myndigheter = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Ansökan om bidrag och stöd = 699 kr/st
                    </li>
                  </div>
                </div>

                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Framtagning av avtal = fr. 599 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Avtalsgranskning = 399 kr/dokument
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="product__list">
          <div className="product__content">
            <div className="card-title">
              <h3>Kreatörer och influencers</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__list">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Administration - samarbete = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Mötesbokning och schemaläggning = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Kundsupport = 299kr/h</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Framtagning av presentationsmaterial, pitch mail osv. =
                      249 kr/st
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Framtagning av mallar (exkl. avtal) = 249 kr/st
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skräddarsydd media portfolio = 499 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Reseplanering och koordinering = 249 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Framtagning av avtal = fr. 599 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Avtalsgranskning = 399 kr/st
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Övrig rådgivning = 499 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Övrig administrativ assistans = 499 kr/h
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div className="product-list__spc">
          <div className="product__content">
            <div className="card-title__spc">
              <h3>Nystartspaket 4 999 kr</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__listTwo">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Registrera för F-skatt</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Registrera på Bolagsverket
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Setup Google profil</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Setup Bokföringssystem</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Setup marknadsföringsverktyg och Google Analytics
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Assistans med att öppna företagskonto
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">2h rådgivning</li>
                  </div>
                </div>
                <div className="card-text">
                  <p>
                    Kontakta gärna oss och berätta om dina behov så sätter vi
                    ihop en offert till dig där vi inkluderar alla priser som
                    tillgodoser dina önskemål.
                  </p>
                  <p>
                    Observera att priserna på sidan kan komma att ändras och vi
                    reserverar oss för eventuella feltryck i prislistan och
                    förbehåller oss rätten att justera priserna. Alla våra
                    priser i vår e-handel anges i SEK och alla priser är
                    inklusive 25% moms.
                  </p>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priser;
