import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { BsCheck } from "react-icons/bs";
import "./PrivatPersoner.css";

const Influencer = () => {
  return (
    <section className="privatPersoner">
      <div className="privatPersoner__header">
        <div className="privatPersoner__content">
          <h1 className="privatPersoner__title animate__animated animate__fadeIn ">
            Administration för <br /> privatpersoner
          </h1>

          <p className="privatPersoner__text animate__animated animate__fadeIn">
            Vi erbjuder privatpersoner hjälp med all typ av administration och
            gör det enkelt för dig att få den assistans du behöver.
          </p>
        </div>
        <div className="image__gradient"></div>
      </div>
      <div className="company__services">
        <div className="company__servicesContent">
          <h3>Administration</h3>
          <h2 className="headingTwo">För privatpersoner</h2>
          <p className="text">
            Vi erbjuder professionell administrativ assistans inom en rad olika
            områden. Nedan finner du exempel på våra tjänster som är anpassade
            för dig som privatperson. Alla våra tjänster skräddarsys efter dina
            unika behov och vi välkomnar dig att kontakta oss för en kostnadsfri
            offert och en gratis första konsultation.
          </p>

          <div className="companyServices__button">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>

        <div className="company__serviceContainer">
          <div className="companyService-img">
            <img src={images.privatpersonerimg2} alt="" />
          </div>
        </div>
      </div>

      <div className="company-sectionTwo">
        <div className="pb-imgBox">
          <img src={images.privatpersonerhome} alt="" />
        </div>
        <div className="company-textContentTwo">
          <h2 className="headingTwo animate__animated animate__zoomIn">
            Våra tjänster till privatpersoner
          </h2>
          <ul className="company-list">
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Hjälp med att betala fakturor</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Inköp </li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Resebokning och planering</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Avtalsframtagning och avtalsgranskning</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Avtalsmallar</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Ansökan om bidrag och stöd</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Migrationsärenden och rådgivning</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Kontakt med myndigheter</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Övrig privatadministration</li>
            </div>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Influencer;
