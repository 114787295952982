import React from "react";
import { Link } from "react-router-dom";
import { BiMessageRounded } from "react-icons/bi";
import "./MessageButton.css";

const MessageButton = () => {
  return (
    <div className="message animate__animated animate__fadeIn">
      <Link className="link" to="/kontakta-oss">
        <button className="message__button">
          <BiMessageRounded className="message__icon" />
        </button>
      </Link>
    </div>
  );
};

export default MessageButton;
