import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { BsCheck } from "react-icons/bs";
import "animate.css/animate.min.css";
import "./Företag.css";

const Företag = () => {
  return (
    <section className="company">
      <div className="company__header">
        <div className="company__content">
          <h1 className="company__title animate__animated animate__fadeIn ">
            Administration för <br /> företag
          </h1>

          <p className="company__text animate__animated animate__fadeIn">
            Vi erbjuder administrativa lösningar för företag som vill outsourca
            sin administration. Hos oss får du experthjälp till ett rimligt
            pris.
          </p>
          <div className="home__btn animate__animated animate__zoomIn ">
            <Link to="/offert-förfrågan">
              <button>Begär en offert</button>
            </Link>
          </div>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="company__services">
        <div className="company__servicesContent">
          <h3>RL Deskservice</h3>
          <h2 className="headingTwo ">Företagsadministration</h2>
          <p className="text">
            Vårt mål är att vara ett värdefullt komplement för företag, för att
            göra det enkelt för våra kunder att kunna delegera sina
            administrativa uppgifter till oss. Vi hanterar alla ärenden, oavsett
            storlek och vi skräddarsyr alla våra tjänster för att passa just er.
          </p>
          <p className="text">
            Går du i tankar om att starta ett företag, eller har du precis
            lanserat? Vi hjälper dig även med allt redan från start!
          </p>

          <div className="companyServices__button">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>

        <div className="company__serviceContainer">
          <div className="companyService-img">
            <img src={images.Företaghome} alt="" />
          </div>
        </div>
      </div>

      <div className="company-services">
        <div className="grid-container">
          <h2 className="headingTwo animate__animated animate__fadeInLeft">
            Våra tjänster
          </h2>

          <div className="company-grid">
            <div className="company-grid__item">
              <div className="company-card">
                <div className="card-content">
                  <div className="card-heading">
                    <h2>Löpande administration</h2>
                  </div>
                  <ul className="card-list">
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Fakturering</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Kvittohantering</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Bokföring</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Momsredovisning</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          HR och personalfrågor
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Inköp</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Reseplanering, möteshantering och tidsplanering
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Kundsupport och ärendehantering
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="company-grid__item large">
              <div className="company-card">
                <div className="card-content">
                  <div className="card-heading">
                    <h2>Löneadministration</h2>
                  </div>

                  <ul className="card-list">
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Skatteinbetalning och arbetsgivaravgifter
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Arbetsgivardeklaration
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Tjänstepension</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Semester, friskvård och andra förmåner{" "}
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Löneutbetalning + lönebesked
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="company-grid__item">
              <div className="company-card">
                <div className="card-content">
                  <div className="card-heading">
                    <h2>Juridisk rådgivning och ärendehantering</h2>
                  </div>

                  <ul className="card-list">
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Framtagning av företagsavtal, avtalsmallar och policys
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Ansökan om arbetstillstånd
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Myndighetsärenden inklusive migrationsfrågor
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="company-sectionTwo">
        <div className="company-textContentTwo">
          <h2 className="headingTwo">Vid uppstart av företag</h2>

          <ul className="company-list">
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Registrera F-skatt</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Registrera bolag hos Bolagsverket och andra myndigheter</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Setup av bokföringssystem</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Framtagning av policys och avtalsmallar</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Assistera med framtagning av företagsrutiner</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Skapa Google profil</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Skapa hemsida och e-handel (utförs av webbutvecklare)</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Setup marknadsföringsverktyg på hemsida</li>
            </div>
          </ul>
        </div>
        <div className="company-imgBox">
          <img src={images.företagstart} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Företag;
