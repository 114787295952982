import React from "react";
import { Link } from "react-router-dom";
import "./Offert.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Offert = () => {
  const zohoOffertForm = `<iframe aria-label='Offert förfrågan' frameborder="0" style="height:2200px;width:100%;border:none;" src='https://forms.zohopublic.eu/rldeskserviceab1/form/Offertfrfrgan1/formperma/BqLEgkEpA5ABMP1gAxlOM1_dEf0p6OL9SSTWNjoK6Ok'></iframe>`;
  return (
    <div className="offert">
      <div className="offert-header">
        <div className="offert-content">
          <h1 className="offert-title animate__animated animate__fadeIn ">
            Få en kostnadsfri offert
          </h1>

          <p className="offert-text animate__animated animate__fadeInUp animate__slow	1s ">
            Fyll gärna i formuläret nedan för en skräddarsydd offert utifrån
            dina behov. Har du några frågor eller funderingar så kan du höra av
            dig till vår kundservice.
          </p>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="offert-form">
        <div
          className="zoho-container"
          dangerouslySetInnerHTML={{ __html: zohoOffertForm }}
        />
      </div>
    </div>
  );
};

export default Offert;
