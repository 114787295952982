import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { BsCheck } from "react-icons/bs";
import "./Influencers.css";

const Influencers = () => {
  return (
    <section className="company">
      <div className="infl-header">
        <div className="company__content">
          <h1 className="company__title animate__animated animate__fadeIn ">
            Administration för <br /> kreatörer
          </h1>

          <p className="company__text animate__animated animate__fadeIn ">
            Vi erbjuder alla influencers, kreatörer och frilansare
            administrativa tjänster som passar dess behov. Vi hjälper dig
            oavsett hur stort eller litet ditt ärende är. Du kan enkelt skicka
            en offertförfrågan genom knappen nedan.
          </p>
          <div className="egenanstallda__btn animate__animated animate__zoomIn ">
            <Link to="/offert-förfrågan">
              <button>Be om offert</button>
            </Link>
          </div>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="infl-info">
        <div className="aboutUs__infoContent">
          <h3>Administration</h3>
          <h2 className="headingTwo ">För kreatörer och influencers</h2>
          <p className="text">
            Som influencer är det inte alltid lätt att veta hur man ska sköta
            sin administration, hålla reda på lagar och regler, hur dessa skall
            tillämpas samtidigt som man ska skapa Content och arbeta med sin
            passion!
          </p>

          <p className="text">
            Vissa aspekter av influencer eller frilansbranschen kan kännas
            knepiga. Det kan vara svårt att navigera och känna till the do's and
            don'ts. I andra fall kanske man har stenkoll på allt men inte hinner
            sköta det administrativa själv utan man vill hellre ägna sig åt de
            aspekterna som man brinner för. Oavsett så hjälper vi dig igenom all
            form av administration. Inget ärende är för litet eller för stort
            för oss och vi strävar alltid efter att underlätta för dig som kund
            hos oss.
          </p>

          <p className="text">
            Vårt mål är att göra administrativ hjälp lättillgänglig för alla och
            att alla ska kunna få det när man behöver det, vilket är varför vi
            har ett brett sortiment av tjänster som vi skräddarsyr enligt våra
            kunders önskemål för att tillgodose dess behov.
          </p>
        </div>
      </div>

      <div className="company-sectionTwo">
        <div className="company-textContentTwo">
          <h2 className="headingTwo ">Våra tjänster</h2>

          <ul className="service-list ">
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Fakturering och kvittohantering</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>
                Informationslagring av produktinköp och inköp av tjänster inför
                skattedeklaration
              </li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Avtalsgranskning och avtalsframtagning</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Rådgivning kring skattefrågor, avtal etc.</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Resebokning och reseplanering</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Mail korrespondens och kundsupport på kundens vägnar</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Skräddarsydda Mediakits</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Framtagning av presentations - och pitch material</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Mötesbokning och schemaläggning</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Inköp </li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Skapa och skicka offerter på kundens vägnar</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Övrig administrativ assistans</li>
            </div>
          </ul>

          <div className="infl__button">
            <Link to="/priser">
              <button>Vår Prislista</button>
            </Link>
          </div>
        </div>
        <div className="infls-imgBox">
          <img src={images.kreatörerimg2} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Influencers;
