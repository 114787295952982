import React from "react";
import { IoMdClose } from "react-icons/io";
import "./Nyhetsbrev.css";

const Nyhetsbrev = ({ closePopup }) => {
  const zohoNyhetsbrevForm = `<iframe aria-label='Prenumerera på vårt nyhetsbrev!' frameborder="0" style="height:600px;width:99%;border:none;" src='https://forms.zohopublic.eu/rldeskserviceab1/form/Signup/formperma/SdPwHjQEzT1-nbmEVw_jIM04V_JmdaWcb0ysSLadOVE'></iframe>`;
  return (
    <div className="overlay">
      <div className="nyhetsbrev">
        <div className="close-icon">
          <IoMdClose onClick={closePopup} className="close" />
        </div>

        <div dangerouslySetInnerHTML={{ __html: zohoNyhetsbrevForm }} />
      </div>
    </div>
  );
};

export default Nyhetsbrev;
